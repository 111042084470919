import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { ofType } from 'redux-observable';
import { combineEpics } from 'redux-observable';

import * as fromAction from '../reducers/database.slice';
import { IRequestId, IResponseObj } from '../../models/base.model';

import DatabaseService from '../../services/database.service';

export const databaseEpic = combineEpics(
    // Backup Request
    (action$: any) => {
        return action$.pipe( 
            ofType (fromAction.backupRequestStarted),
            switchMap(() => 
                DatabaseService.backupRequest()
            .pipe(
                map((response: any) => {
                    if (response) {
                        return fromAction.backupRequestCompleted(response);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    
)