import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import IAppState from '../states/app-state';
import authSlice from './auth.slice';
import groupDeviceSlice from './group-device.slice';
import accountSlice from './account.slice';
import planSlice from './plan.slice';
import databaseSlice from './database.slice';

// combineReducers will be handled internally by configureStore
const rootReducer = (history: History) => {
    return combineReducers<IAppState>({
        router: connectRouter(history),
        authState: authSlice,
        groupDeviceState: groupDeviceSlice,
        accountState: accountSlice,
        planState: planSlice,
        databaseState: databaseSlice
    });
}

export default rootReducer;


