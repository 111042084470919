import { Observable } from 'rxjs/internal/Observable';

import { get, post } from './../services/api-base.service';
import { IRequestId, IResponseObj } from '../models/base.model';
import { IDeviceNameUpdateInputDto, IDeviceOutputDto, IDeviceSearchInputDto, IRequestAddDeviceGroupDto } from '../models/device.model';
import { IMemberSearchInputDto, IMemberSearchOutputDto } from '../models/member.model';
import {
    IGroupDetailDto,
    IGroupOutputDto,
    IGroupTrackingUpdateNameInputDto,
    IJoinMemberGroupInputDto,
    IRequestDeleteDeviceGroupDto,
    IDisableDeviceInputDto,
    IRequestHandlingMemberDto,
    ITrackingNewGroup
} from '../models/tracking-group.model';

export default class GroupDeviceService {
    static myTrackingGroup() : Observable<IResponseObj<IGroupOutputDto[]>> {
        return get('tracking/my-tracking-groups');
    }

    static addNewGroup(data: ITrackingNewGroup) : Observable<IResponseObj<string>> {
        return post('tracking/add-group', data);
    }

    static deleteGroup(data: IRequestId<string>) : Observable<IResponseObj<boolean>> {
        return post('tracking/delete-group', data);
    }

    static fetchTrackingDetail(data: IRequestId<string>) : Observable<IResponseObj<IGroupDetailDto>> {
        return post('tracking/get-tracking', data);
    }

    static updateNameGroup(data: IGroupTrackingUpdateNameInputDto) : Observable<IResponseObj<boolean>> {
        return post('tracking/update-name-group', data);
    }

    static updateActiveGroup(data: IRequestId<string>) : Observable<IResponseObj<boolean>> {
        return post('tracking/update-active-group', data);
    }
    
    static searchUnassignedDevice(data: IDeviceSearchInputDto) : Observable<IResponseObj<IDeviceOutputDto[]>> {
        return post('tracking/search-unassigned-device', data);
    }

    static deleteDeviceGroup(data: IRequestDeleteDeviceGroupDto) : Observable<IResponseObj<boolean>> {
        return post('tracking/delete-device-group', data);
    }

    static disableDevice(data: IDisableDeviceInputDto) : Observable<IResponseObj<boolean>> {
        return post('tracking/disable-device', data);
    }

    static addDeviceGroup(data: IRequestAddDeviceGroupDto) : Observable<IResponseObj<IDeviceOutputDto>> {
        return post('tracking/add-device-group', data);
    }

    static handleMemberGroup(data: IRequestHandlingMemberDto) : Observable<IResponseObj<boolean>> {
        return post('tracking/handle-member-group', data);
    }

    static joinMemberGroup(data: IJoinMemberGroupInputDto) : Observable<IResponseObj<boolean>> {
        return post('tracking/join-member-group', data);
    }

    static searchUnassignedMember(data: IMemberSearchInputDto) : Observable<IResponseObj<IMemberSearchOutputDto[]>> {
        return post('tracking/join-member-group', data);
    }

    static updateDeviceName(data: IDeviceNameUpdateInputDto) : Observable<IResponseObj<boolean>> {
        return post('device/update-device-name', data);
    }
}
