import { Observable } from 'rxjs/internal/Observable';

import { post, get } from './api-base.service';
import { IRequestId, IResponseObj } from '../models/base.model';
import {
    IMyLicenseInfoOutputDto,
    IPlanInfoAddOutputDto,
    IPlanInfoChangePositionInputDto,
    IPlanInfoChangePositionOutputDto,
    IPlanInfoInputDto,
    IPlanInfoOutputDto,
    IPlanInfoUpdateStatusInputDto
} from '../models/plan.model';

export default class PlanService {
    static addNewPlan(data: IPlanInfoInputDto) : Observable<IResponseObj<IPlanInfoAddOutputDto>> {
        return post('plan/add-plan', data);
    }

    static updatePlan(data: IPlanInfoInputDto) : Observable<IResponseObj<boolean>> {
        return post('plan/update-plan', data);
    }

    static updateStatusPlan(data: IPlanInfoUpdateStatusInputDto) : Observable<IResponseObj<boolean>> {
        return post('plan/update-status-plan', data);
    }

    static deletePlans(data: IRequestId<string>) : Observable<IResponseObj<boolean>> {
        return post('plan/delete-plan', data);
    }

    static allPlans() : Observable<IResponseObj<IPlanInfoOutputDto[]>> {
        return get('plan/plans');
    }

    static findPlan(data: IRequestId<string>) : Observable<IResponseObj<IPlanInfoOutputDto>> {
        return post('plan/find-plan', data);
    }

    static changePosition(data: IPlanInfoChangePositionInputDto) : Observable<IResponseObj<IPlanInfoChangePositionOutputDto>> {
        return post('plan/change-position', data);
    }

    static avaiablePlan() : Observable<IResponseObj<IPlanInfoOutputDto[]>> {
        return get('plan/avaiable-plan');
    }

    static registerLicense(data:IRequestId<string>) : Observable<IResponseObj<boolean>> {
        return post('plan/register-license', data);
    }

    static myLicenses() : Observable<IResponseObj<IMyLicenseInfoOutputDto[]>> {
        return get('plan/my-licenses');
    }
}