import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { ofType } from 'redux-observable';
import { combineEpics } from 'redux-observable';

import * as fromAction from '../reducers/plan.slice';
import { IRequestId, IResponseObj } from '../../models/base.model';

import PlanService from '../../services/plan.service';
import { IMyLicenseInfoOutputDto, IPlanInfoAddOutputDto, IPlanInfoChangePositionInputDto, IPlanInfoChangePositionOutputDto, IPlanInfoInputDto, IPlanInfoOutputDto, IPlanInfoUpdateStatusInputDto } from '../../models/plan.model';

export const planEpic = combineEpics(
    // Get All Plans
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.fetchAllPlansStarted),
            switchMap(() => 
                PlanService.allPlans()
            .pipe(
                map((response: IResponseObj<IPlanInfoOutputDto[]>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.fetchAllPlansCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Avaiable Plans
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.fetchAvaiablePlansStarted),
            switchMap(() => 
                PlanService.avaiablePlan()
            .pipe(
                map((response: IResponseObj<IPlanInfoOutputDto[]>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.fetchAvaiablePlansCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Find Plan by Id
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.fetchPlanByIdStarted),
            switchMap((inputData: IRequestId<string>) => 
                PlanService.findPlan(inputData)
            .pipe(
                map((response: IResponseObj<IPlanInfoOutputDto>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.fetchPlanByIdCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Add New Plan
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.addNewPlanStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IPlanInfoInputDto) => 
                PlanService.addNewPlan(inputData)
            .pipe(
                map((response: IResponseObj<IPlanInfoAddOutputDto>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.addNewPlanCompleted({
                            id: response.data.id,
                            name: inputData.name,
                            price: inputData.price,
                            duration: inputData.duration,
                            durationContent: inputData.durationContent,
                            content1: inputData.content1,
                            content2: inputData.content2,
                            content3: inputData.content3,
                            isActived: inputData.isActived,
                            position: response.data.position,
                        });
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Update Plan
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.updatePlanStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IPlanInfoInputDto) => 
                PlanService.updatePlan(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.updatePlanCompleted({
                            id: inputData.id,
                            name: inputData.name,
                            price: inputData.price,
                            duration: inputData.duration,
                            durationContent: inputData.durationContent,
                            content1: inputData.content1,
                            content2: inputData.content2,
                            content3: inputData.content3,
                            isActived: inputData.isActived,
                            position: inputData.position
                        });
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Update Status Plan
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.updateStatusPlanStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IPlanInfoUpdateStatusInputDto) => 
                PlanService.updateStatusPlan(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.updateStatusPlanCompleted({
                            id: inputData.id,
                            isActived: inputData.isActived
                        });
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Delete Plan
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.deletePlanStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IRequestId<string>) => 
                PlanService.deletePlans(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.deletePlanCompleted(inputData.id);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Change Position
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.changePositionPlanStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IPlanInfoChangePositionInputDto) => 
                PlanService.changePosition(inputData)
            .pipe(
                map((response: IResponseObj<IPlanInfoChangePositionOutputDto>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.changePositionPlanCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Register License
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.registerLicenseStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IRequestId<string>) => 
                PlanService.registerLicense(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.registerLicenseCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // My Licenses
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.myLicesnsesStarted),
            map((action: any) => action.payload),
            switchMap(() => 
                PlanService.myLicenses()
            .pipe(
                map((response: IResponseObj<IMyLicenseInfoOutputDto[]>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.myLicensesCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
)
// PlansService