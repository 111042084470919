
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { ofType } from 'redux-observable';
import { combineEpics } from 'redux-observable';

import * as fromAction from '../reducers/group-device.slice';
import { IRequestId, IResponseObj } from '../../models/base.model';

import TrackingService from '../../services/tracking.service';
import {
    IDisableDeviceInputDto,
    IGroupDetailDto,
    IGroupOutputDto,
    IGroupTrackingUpdateNameInputDto,
    IJoinMemberGroupInputDto,
    IRequestDeleteDeviceGroupDto,
    IRequestHandlingMemberDto,
    ITrackingNewGroup
} from '../../models/tracking-group.model';
import { IDeviceNameUpdateInputDto, IDeviceOutputDto, IDeviceSearchInputDto, IRequestAddDeviceGroupDto } from '../../models/device.model';
import { IMemberSearchInputDto, IMemberSearchOutputDto } from '../../models/member.model';

export const groupDeviceEpic = combineEpics(
    // Get my group
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.fetchMyTrackingGroupStarted),
            switchMap(() => 
                TrackingService.myTrackingGroup()
            .pipe(
                map((response: IResponseObj<IGroupOutputDto[]>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.fetchMyTrackingGroupCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Add New Group
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.addNewGroupStarted),
            map((action: any) => action.payload),
            switchMap((inputData: ITrackingNewGroup) => 
                TrackingService.addNewGroup(inputData)
            .pipe(
                map((response: IResponseObj<string>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.addNewGroupCompleted({
                            id: response.data,
                            name: inputData.name
                        });
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Delete Group
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.deleteGroupStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IRequestId<string>) => 
                TrackingService.deleteGroup(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed && response.data) {
                        return fromAction.deleteGroupCompleted(inputData.id);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Get Detail
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.fetchGroupDetailStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IRequestId<string>) => 
                TrackingService.fetchTrackingDetail(inputData)
            .pipe(
                map((response: IResponseObj<IGroupDetailDto>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.fetchGroupDetailCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Update Group Name
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.updateNameGroupStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IGroupTrackingUpdateNameInputDto) => 
                TrackingService.updateNameGroup(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed && response.data) {
                        return fromAction.updateNameGroupCompleted({
                            id: inputData.id,
                            name: inputData.name
                        });
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Update Active Group
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.updateActivedGroupStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IRequestId<string>) => 
                TrackingService.updateActiveGroup(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.updateActivedGroupCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Search Unassigned Device
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.searchUnassignedDeviceStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IDeviceSearchInputDto) => 
                TrackingService.searchUnassignedDevice(inputData)
            .pipe(
                map((response: IResponseObj<IDeviceOutputDto[]>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.searchUnassignedDeviceCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Unassign Device out Group
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.deleteDeviceGroupStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IRequestDeleteDeviceGroupDto) => 
                TrackingService.deleteDeviceGroup(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed && response.data) {
                        return fromAction.deleteDeviceGroupCompleted(inputData.deviceId);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Disbale Device
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.disableDeviceStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IDisableDeviceInputDto) => 
                TrackingService.disableDevice(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed && response.data) {
                        return fromAction.disableDeviceCompleted({
                            deviceId: inputData.deviceId,
                            result: response.data,
                            flag: inputData.flag
                        });
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Assign Device to Group
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.addDeviceGroupStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IRequestAddDeviceGroupDto) => 
                TrackingService.addDeviceGroup(inputData)
            .pipe(
                map((response: IResponseObj<IDeviceOutputDto>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.addDeviceGroupCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Handle Member in Group
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.handleMemberGroupStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IRequestHandlingMemberDto) => 
                TrackingService.handleMemberGroup(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed && response.data) {
                        return fromAction.handleMemberGroupCompleted({
                            memberId: inputData.memberId,
                            action: inputData.action,
                            newRole: inputData.newRole
                        });
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Join Member to Group
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.handleJoinMemberGroupStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IJoinMemberGroupInputDto) => 
                TrackingService.joinMemberGroup(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed && response.data) {
                        return fromAction.handleJoinMemberGroupCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Search Unassigned Member
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.searchUnassignedMemberStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IMemberSearchInputDto) => 
                TrackingService.searchUnassignedMember(inputData)
            .pipe(
                map((response: IResponseObj<IMemberSearchOutputDto[]>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.searchUnassignedMemberCompleted(response.data);
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
    // Edit Device Name
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.updateDeviceNameStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IDeviceNameUpdateInputDto) => 
                TrackingService.updateDeviceName(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed && response.data) {
                        return fromAction.updateDeviceNameCompleted({
                            deviceId: inputData.deviceId,
                            deviceName: inputData.deviceName
                        });
                    } else {
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    return of(fromAction.execError(err));
                })
            ))
        );
    },
)
