import {configureStore} from '@reduxjs/toolkit'
import {createEpicMiddleware} from 'redux-observable';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from './reducers/root-reducer';
import { rootEpic } from './epics/root-epic';

const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware();
const preloadedState = {};

const store = configureStore({
    reducer: rootReducer(history),
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
          serializableCheck: false,
        }).concat(routerMiddleware(history)),
        epicMiddleware,
    ],
    preloadedState
});

epicMiddleware.run(rootEpic);

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

