import {DEPLOYMENT_MODE} from './../core/deployment.const';
import {BACKEND_DEVELOPMENT_URL} from './env-developement';
import {BACKEND_PRODUCTION_URL} from './env-production';
import {isProductionMode} from './app-config';

const { REACT_APP_ENV } = process.env;

let url = BACKEND_DEVELOPMENT_URL;
if ((window as any).Configs) {
    if ((window as any).Configs.deploymentEnv === DEPLOYMENT_MODE.DEV) {
        url = BACKEND_DEVELOPMENT_URL;
    } else if ((window as any).Configs.deploymentEnv === DEPLOYMENT_MODE.PROD) {
        url = BACKEND_PRODUCTION_URL;
    }
} else {
    url = isProductionMode ? BACKEND_PRODUCTION_URL : BACKEND_DEVELOPMENT_URL;
}

export const BACKEND_URL = url;