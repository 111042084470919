import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';


import RoutersLayout from './../routers';

function App() {
  
  return (
    <Suspense fallback={<h2>Loading...</h2>}>
      <Router>
          <RoutersLayout/>
      </Router>
    </Suspense>
  );
}

export default App;
