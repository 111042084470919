import { combineEpics } from 'redux-observable';
import { authEpic } from './auth.epic';
import { groupDeviceEpic } from './group-device.epic';
import { accountEpic } from './account.epic';
import { planEpic } from './plan.epic';
import { databaseEpic } from './database.epic';

export const rootEpic = combineEpics(
    authEpic,
    groupDeviceEpic,
    accountEpic,
    planEpic,
    databaseEpic
);