import { AxiosInstance } from 'axios'
import localStorageService from './localstorage.service'
import {BACKEND_URL} from '../environment/app-environment';

export default function initInterceptors(httpService: AxiosInstance) {
    // Add a request interceptor
    httpService.interceptors.request.use(
        (config) => {
            const token = localStorageService.getAccessToken();
        
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token
            }

            return config
        },
        async (error) => {
            return Promise.reject(error)
        }
    );

    httpService.interceptors.response.use(
        (res) => res,
        async (err) => {
            const originalRequest = err.config
            if (
                err.response.status === 401 &&
                originalRequest.url === BACKEND_URL + '/api/auth/token'
            ) {
                //navigate('/login');
                return Promise.reject(err)
            }

            if (err.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true
                const refreshToken = localStorageService.getRefreshToken();
                
                if (refreshToken == null || refreshToken.length === 0) {
                    // const navigate = useNavigate();
                    localStorageService.clearData();
                    window.location.href = '/';
                    return Promise.reject(err)
                }

                if (err.response.data != null && (
                    err.response.data.errorCode === 15 ||
                    err.response.data.errorCode === 16 ||
                    err.response.data.errorCode === 17 ||
                    err.response.data.errorCode === 18)) {

                    localStorageService.clearData();
                    window.location.href = '/';
                    return Promise.reject(err)
                }

                try {
                    const rs = await httpService //await apiBase.getInstance
                        .post('/api/auth/refresh-token', {
                            refreshToken: refreshToken
                        });

                    if (rs == null) {
                        window.location.href = '/';
                        localStorageService.clearData();
                        return Promise.reject(err)
                    }

                    const errCode = +rs.data.errorCode;
                    if (!Number.isNaN(errCode)) {
                        switch(errCode) {
                            case 19:
                                localStorageService.clearData();
                                window.location.href = '/waiting-approved';
                                return Promise.reject(err) 

                            case 20:
                                localStorageService.clearData();
                                window.location.href = '/account-locked';
                                return Promise.reject(err)

                            default:
                                break;
                        }
                    }
                    
                    localStorageService.setToken(rs.data.data.token);
                    localStorageService.setLocalStorage('functions', JSON.stringify(rs.data.data.functions));
                    localStorageService.setRefreshToken(rs.data.data.refreshToken);

                    httpService.defaults.headers.common['Authorization'] = `Bearer ${rs.data.data.token}`;
                    originalRequest.headers['Authorization'] = `Bearer ${rs.data.data.token}`;
                    
                    return httpService(originalRequest);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }

            return Promise.reject(err)
        }
    )
}