import { Observable } from 'rxjs/internal/Observable';

import { post } from './../services/api-base.service';
import { IResponseObj } from '../models/base.model';
import { ITokenRequestDto, ITokenResponseDto } from '../models/auth.model';

export default class AuthService {
    static fetchToken(data: ITokenRequestDto) : Observable<IResponseObj<ITokenResponseDto>> {
        return post('auth/token', data);
    }
}