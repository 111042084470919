import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestId } from '../../models/base.model';
import { IDeviceNameUpdateInputDto, IDeviceNameUpdateOutputDto, IDeviceOutputDto, IDeviceSearchInputDto, IRequestAddDeviceGroupDto } from '../../models/device.model';
import { IMemberOutputDto, IMemberSearchOutputDto } from '../../models/member.model';
import { IDisableDeviceInputDto, IDisableDeviceOutputDto, IGroupDetailDto, IGroupExtendOutputDto, IGroupOutputDto, IGroupTrackingUpdateNameInputDto, IGroupTrackingUpdateNameOutputDto, IHandlingMemberOutputDto, IRequestHandlingMemberDto, ITrackingNewGroup, ITrackingNewGroupOuputDto } from '../../models/tracking-group.model';
import { ICommonState } from '../states/common.state';

export interface IGroupDeviceState extends ICommonState{
    groups: Array<IGroupExtendOutputDto>;
    groupDetail: IGroupDetailDto | null;
    searchDevices: Array<IDeviceOutputDto>;
    searchMembers: Array<IMemberSearchOutputDto>;
}

export const initialState: IGroupDeviceState = {
    error: '',
    loaded: false,
    loading: false,
    groups: [],
    groupDetail: null,
    searchDevices: [],
    searchMembers: []
}

export const groupDeviceSlice = createSlice({
    name: 'group-device',
    initialState,
    reducers: {
        // Reset
        resetError: (state: IGroupDeviceState, action: PayloadAction<string>) => {
            state.loading = false;
            state.loaded = true;
            state.error = action.payload;
        },
        // Error
        execError: (state: IGroupDeviceState, action: PayloadAction<string>) => {
            state.loading = false;
            state.loaded = true;
            state.error = action.payload;
        },
        // My Group
        fetchMyTrackingGroupStarted: (state: IGroupDeviceState) => {
            state.loading = true;
            state.loaded = false;
        },
        fetchMyTrackingGroupCompleted: (state: IGroupDeviceState, action: PayloadAction<IGroupOutputDto[]>) => {
            let newGroups: IGroupExtendOutputDto[] = []; 
            for (const item of action.payload) {
                newGroups.push({
                    id: item.id,
                    name: item.name,
                    index: newGroups.length + 1,
                    devices: item.devices,
                    members: item.members,
                    hasDeviceOffline: item.hasDeviceOffline,
                    isActived: item.isActived
                });
            }

            // Update state
            state.loading = false;
            state.loaded = true;
            state.groups = newGroups;
        },
        // Add
        addNewGroupStarted: (state: IGroupDeviceState, action: PayloadAction<ITrackingNewGroup>) => {
            state.loading = true;
            state.loaded = false;
        },
        addNewGroupCompleted: (state: IGroupDeviceState, action: PayloadAction<ITrackingNewGroupOuputDto>) => {
            const groups = [...state.groups];
            let newGroups: IGroupExtendOutputDto[] = []; 
            for (const item of groups) {
                newGroups.push(item);
            }

            newGroups.push({
                id: action.payload.id,
                name: action.payload.name,
                index: groups.length + 1,
                devices: 0,
                members: 0,
                hasDeviceOffline: false,
                isActived: false
            });

            state.loading = false;
            state.loaded = true;
            state.groups = newGroups;
        },
        // Delete
        deleteGroupStarted: (state: IGroupDeviceState, action: PayloadAction<IRequestId<string>>) => {
            state.loading = true;
            state.loaded = false;
        },
        deleteGroupCompleted: (state: IGroupDeviceState, action: PayloadAction<string>) => {
            const groups = [...state.groups];
            let newGroups: IGroupExtendOutputDto[] = []; 
            for (const item of groups) {
                if(item.id !== action.payload) {
                    newGroups.push(item);
                }
            }

            state.loading = false;
            state.loaded = true;
            state.groups = newGroups;
        },
        // Update Name Group
        updateNameGroupStarted: (state: IGroupDeviceState, action: PayloadAction<IGroupTrackingUpdateNameInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        updateNameGroupCompleted: (state: IGroupDeviceState, action: PayloadAction<IGroupTrackingUpdateNameOutputDto>) => {
            const groups = [...state.groups];
            let newGroups: IGroupExtendOutputDto[] = []; 
            for (const item of groups) {
                if(item.id === action.payload.id) {
                    newGroups.push({
                        ...item,
                        name: action.payload.name
                    });
                } else {
                    newGroups.push(item);
                }
            }
    
            state.loading = false;
            state.loaded = true;
            state.groups = newGroups;
        },
        // Update Active/Inactive group
        updateActivedGroupStarted: (state: IGroupDeviceState, action: PayloadAction<IRequestId<string>>) => {
            state.loading = true;
            state.loaded = false;
        },
        updateActivedGroupCompleted: (state: IGroupDeviceState, action: PayloadAction<boolean>) => {
            const groupDetail = state.groupDetail;
            const groups = [...state.groups];
            let newGroups: IGroupExtendOutputDto[] = []; 
            for (const item of groups) {
                if(item.id === groupDetail?.id) {
                    newGroups.push({
                        ...item,
                        isActived: action.payload ?? false
                    });
                } else {
                    newGroups.push(item);
                }
            }

            let newGroupDetail: IGroupDetailDto = {
                id: groupDetail?.id  ?? '',
                devices: groupDetail?.devices ?? [],
                isAdminRole: groupDetail?.isAdminRole ?? false,
                members: groupDetail?.members ?? [],
                name: groupDetail?.name ?? '',
                isActived: action.payload ?? false
            }

            state.loading = false;
            state.loaded = true;
            state.groups = newGroups;
            state.groupDetail = newGroupDetail;
        },
        // Detail
        fetchGroupDetailStarted: (state: IGroupDeviceState, action: PayloadAction<IRequestId<string>>) => {
            state.loading = true;
            state.loaded = false;
        },
        fetchGroupDetailCompleted: (state: IGroupDeviceState, action: PayloadAction<IGroupDetailDto>) => {
            state.loading = false;
            state.loaded = true;
            state.groupDetail = action.payload
        },
        // Search Unassigned Device
        searchUnassignedDeviceStarted: (state: IGroupDeviceState, action: PayloadAction<IDeviceSearchInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        searchUnassignedDeviceCompleted: (state: IGroupDeviceState, action: PayloadAction<IDeviceOutputDto[]>) => {
            state.loading = false;
            state.loaded = true;
            state.searchDevices = [];
            state.searchDevices = action.payload;
        },
        // Update Device Name
        updateDeviceNameStarted: (state: IGroupDeviceState, action: PayloadAction<IDeviceNameUpdateInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        updateDeviceNameCompleted: (state: IGroupDeviceState, action: PayloadAction<IDeviceNameUpdateOutputDto>) => {
            const groupDetail = state.groupDetail;
            let devices: IDeviceOutputDto[] = [];
            for (const item of groupDetail?.devices ?? []) {
                if (item.deviceId != action.payload.deviceId) {
                    devices.push(item);
                    continue;
                }

                devices.push({
                    id: item.id,
                    deviceId: item.deviceId,
                    hasDeviceOffline: item.hasDeviceOffline,
                    name: action.payload.deviceName,
                    hasDeviceDisabled: item.hasDeviceDisabled
                });
            }

            let newGroupDetail: IGroupDetailDto = {
                id: groupDetail?.id  ?? '',
                devices: devices,
                isAdminRole: groupDetail?.isAdminRole ?? false,
                members: groupDetail?.members ?? [],
                name: groupDetail?.name ?? '',
                isActived: groupDetail?.isActived ?? false
            }

            state.loading = false;
            state.loaded = true;
            state.groupDetail = newGroupDetail;
        },
        // Delete Device Group
        deleteDeviceGroupStarted: (state: IGroupDeviceState, action: PayloadAction<IRequestAddDeviceGroupDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        deleteDeviceGroupCompleted: (state: IGroupDeviceState, action: PayloadAction<string>) => {
            const devices = [...state.groupDetail?.devices??[]]
            let newDevices: IDeviceOutputDto[] = [];
            if (action.payload.length > 0) {
                for (const item of devices) {
                    if (item.id === action.payload) {
                        continue;
                    }

                    newDevices.push({
                        id: item.id,
                        deviceId: item.deviceId,
                        name: item.name,
                        hasDeviceOffline: item.hasDeviceOffline,
                        hasDeviceDisabled: item.hasDeviceDisabled
                    });
                }
            }

            const newDetail:IGroupDetailDto = {
                id: state.groupDetail?.id ?? '',
                devices: newDevices,
                isAdminRole: state.groupDetail?.isAdminRole ?? false,
                members: state.groupDetail?.members ?? [],
                name: state.groupDetail?.name ?? '',
                isActived: state.groupDetail?.isActived ?? false
            }

            state.loading = false;
            state.loaded = true;
            state.groupDetail = newDetail
        },
        // Disable Device
        disableDeviceStarted: (state: IGroupDeviceState, action: PayloadAction<IDisableDeviceInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        disableDeviceCompleted: (state: IGroupDeviceState, action: PayloadAction<IDisableDeviceOutputDto>) => {
            const devices = [...state.groupDetail?.devices??[]]
            let newDevices: IDeviceOutputDto[] = [];
            for (const item of devices) {
                if (item.deviceId === action.payload.deviceId) {
                    newDevices.push({
                        id: item.id,
                        deviceId: item.deviceId,
                        name: item.name,
                        hasDeviceOffline: item.hasDeviceOffline,
                        hasDeviceDisabled:
                            action.payload.flag && action.payload.result ? true :
                            action.payload.flag == false && action.payload.result ? false : false
                    });

                    continue;
                }

                newDevices.push({
                    id: item.id,
                    deviceId: item.deviceId,
                    name: item.name,
                    hasDeviceOffline: item.hasDeviceOffline,
                    hasDeviceDisabled: item.hasDeviceDisabled
                });
            }

            const newDetail:IGroupDetailDto = {
                id: state.groupDetail?.id ?? '',
                devices: newDevices,
                isAdminRole: state.groupDetail?.isAdminRole ?? false,
                members: state.groupDetail?.members ?? [],
                name: state.groupDetail?.name ?? '',
                isActived: state.groupDetail?.isActived ?? false
            }

            state.loading = false;
            state.loaded = true;
            state.groupDetail = newDetail
        },
        // Add Device Group
        addDeviceGroupStarted: (state: IGroupDeviceState, action: PayloadAction<IRequestAddDeviceGroupDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        addDeviceGroupCompleted: (state: IGroupDeviceState, action: PayloadAction<IDeviceOutputDto>) => {
            const devices = [...state.groupDetail?.devices??[]]
            const searchDevices = [...state.searchDevices??[]]
            let newDevices: IDeviceOutputDto[] = [];
            if (action.payload !== null && action.payload.id.length > 0) {
                for (const item of devices) {
                    newDevices.push({
                        id: item.id,
                        deviceId: item.deviceId,
                        name: item.name,
                        hasDeviceOffline: item.hasDeviceOffline,
                        hasDeviceDisabled: item.hasDeviceDisabled
                    });
                }

                newDevices.push({
                    id: action.payload.id,
                    deviceId: action.payload.deviceId,
                    name: action.payload.name,
                    hasDeviceOffline: action.payload.hasDeviceOffline,
                    hasDeviceDisabled: action.payload.hasDeviceDisabled
                });
            }

            const newDetail:IGroupDetailDto = {
                id: state.groupDetail?.id ?? '',
                devices: newDevices,
                isAdminRole: state.groupDetail?.isAdminRole ?? false,
                members: state.groupDetail?.members ?? [],
                name: state.groupDetail?.name ?? '',
                isActived: state.groupDetail?.isActived ?? false
            }

            let newSearchDevices: IDeviceOutputDto[] = [];
            for (const item of searchDevices) {
                if (item.id !== action.payload.id) {
                    newSearchDevices.push(item);
                }
            }
    
            state.loading = false;
            state.loaded = true;
            state.groupDetail = newDetail;
            state.searchDevices = newSearchDevices;
        },
        // Handle Member in Group
        handleMemberGroupStarted: (state: IGroupDeviceState, action: PayloadAction<IRequestHandlingMemberDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        handleMemberGroupCompleted: (state: IGroupDeviceState, action: PayloadAction<IHandlingMemberOutputDto>) => {
            const members = [...state.groupDetail?.members??[]]
            let newMembers: IMemberOutputDto[] = [];
            if (action.payload !== null && action.payload.memberId.length > 0) {
                for (const item of members) {
                    if (item.id !== action.payload.memberId) {
                        newMembers.push(item);
                        continue;
                    }

                    let updateItem: IMemberOutputDto = {
                        id: item.id,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        isMyself: item.isMyself,
                        isLocked: item.isLocked,
                        isApproved: item.isApproved,
                        role: item.role
                    };

                    switch(action.payload.action) {
                        case 'approval':
                            updateItem.isApproved = !updateItem.isApproved;
                            break;
                        
                        case 'lock':
                            updateItem.isLocked = !updateItem.isLocked;
                            break;

                        case 'role':
                            updateItem.role = action.payload.newRole;
                            break;
                    }

                    newMembers.push(updateItem);
                }
            }

            const newDetail:IGroupDetailDto = {
                id: state.groupDetail?.id ?? '',
                devices: state.groupDetail?.devices ?? [],
                isAdminRole: state.groupDetail?.isAdminRole ?? false,
                members: newMembers,
                name: state.groupDetail?.name ?? '',
                isActived: state.groupDetail?.isActived ?? false
            }

            state.loading = false;
            state.loaded = true;
            state.groupDetail = newDetail;
        },
        // Join Member Group
        handleJoinMemberGroupStarted: (state: IGroupDeviceState, action: PayloadAction<{groupId: string}>) => {
            state.loading = true;
            state.loaded = false;
        },
        handleJoinMemberGroupCompleted: (state: IGroupDeviceState, action: PayloadAction<boolean>) => {
            state.loading = false;
            state.loaded = true;
        },
        // Search Member
        searchUnassignedMemberStarted: (state: IGroupDeviceState) => {
            state.loading = true;
            state.loaded = false;
        },
        searchUnassignedMemberCompleted: (state: IGroupDeviceState, action: PayloadAction<IMemberSearchOutputDto[]>) => {
            state.loading = false;
            state.loaded = true;
            state.searchMembers = action.payload;
        },
    },
});

export const {
    resetError, execError,
    fetchMyTrackingGroupStarted, fetchMyTrackingGroupCompleted,
    addNewGroupStarted, addNewGroupCompleted,
    deleteGroupStarted, deleteGroupCompleted,
    updateNameGroupStarted, updateNameGroupCompleted,
    updateActivedGroupStarted, updateActivedGroupCompleted,
    fetchGroupDetailStarted, fetchGroupDetailCompleted,
    searchUnassignedDeviceStarted, searchUnassignedDeviceCompleted,
    deleteDeviceGroupStarted, deleteDeviceGroupCompleted,
    addDeviceGroupStarted, addDeviceGroupCompleted, 
    handleMemberGroupStarted, handleMemberGroupCompleted,
    handleJoinMemberGroupStarted, handleJoinMemberGroupCompleted,
    searchUnassignedMemberStarted, searchUnassignedMemberCompleted,
    updateDeviceNameStarted, updateDeviceNameCompleted,
    disableDeviceStarted, disableDeviceCompleted
} = groupDeviceSlice.actions

export default groupDeviceSlice.reducer
