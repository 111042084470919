
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { combineEpics } from 'redux-observable';

import localStorageService from './../../core/localstorage.service'

import * as fromAction from '../reducers/auth.slice';
import { IResponseObj } from '../../models/base.model';

import { ITokenRequestDto, ITokenResponseDto } from '../../models/auth.model';
import AuthService from '../../services/auth.service';

export const authEpic = combineEpics(
    // Get Token after authentication by Google
    (action$:Observable<Action>) => {
        return action$.pipe(
            ofType (fromAction.fetchTokenRequestStarted),
            map((action: any) => action.payload),
            switchMap((inputData: ITokenRequestDto) => 
                AuthService.fetchToken(inputData)
            .pipe(
                map((response: IResponseObj<ITokenResponseDto>) => {
                    if (response && response.isSuccessed) {
                        localStorageService.setToken(response.data.token);
                        localStorageService.setRefreshToken(response.data.refreshToken);
                        localStorageService.setLocalStorage('firstName', response.data.firstName);
                        localStorageService.setLocalStorage('lastName', response.data.lastName);
                        localStorageService.setLocalStorage('avatar', response.data.avatar);
                        localStorageService.setLocalStorage('functions', JSON.stringify(response.data.functions));
    
                        window.location.href = '/manage/dashboard';

                        return fromAction.fetchTokenRequestCompleted(response.data);
                    } else {
                        // throw response;
                        const errCode = +response.errorCode;
                        if (!Number.isNaN(errCode)) {
                            switch(errCode) {
                                case 19:
                                    window.location.href = '/waiting-approved';
                                    return fromAction.execError(response.errorCode);

                                case 20:
                                    window.location.href = '/account-locked';
                                    return fromAction.execError(response.errorCode);

                                default:
                                    break;
                            }
                        }

                        window.location.href = '/contact-admin';
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    let result = {
                        message: err
                    }
                    return of(fromAction.execError(''));
                })
            ))
        );
    },
)
