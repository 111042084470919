import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const isAuthenticated = (): boolean => {
    return true;
}

const withAuth = (Component: any) => {
    return (props: any) => {
        if (!isAuthenticated()) {
            return <Navigate to="/dashboard" />;
        }
        return <Component {...props} />;
    };
};

export interface CustomizeRoute {
    path: string;
    element?: JSX.Element | React.ReactNode | React.FunctionComponent;
    title: string;
    name: string;
    role: string[];
    children?: CustomizeRoute[];
}

const routes: CustomizeRoute[] = [
    {
        path: '/',
        role: [],
        element: React.lazy(() => import('./features/components/layouts/layout-common.component')),
        name: 'Portal',
        title: 'Portal',
        children: [
            {
                path: '',
                role: [],
                element: React.lazy(() => import('./features/pages/home/home.component')),
                name: 'Home',
                title: 'Home',
            }, {
                path: 'home',
                role: [],
                element: React.lazy(() => import('./features/pages/home/home.component')),
                name: 'Home',
                title: 'Home',
            }, {
                path: 'waiting-approved',
                role: [],
                element: React.lazy(() => import('./features/pages/users/account-waiting-approved.component')),
                name: 'Waiting Approval',
                title: 'Waiting Approval',
            }, {
                path: 'account-locked',
                role: [],
                element: React.lazy(() => import('./features/pages/users/account-locked.component')),
                name: 'Account Locked',
                title: 'Account Locked',
            }
        ],
    }
    /*{
        path: '/',
        role: [],
        element: React.lazy(() => import('./features/home/home.component')),
        name: 'Home',
        title: 'Home',
    }, {
        path: '/login',
        role: [],
        element: React.lazy(() => import('./features/login/login.component')),
        name: 'Login',
        title: 'Login',
    }*/, {
        path: '/manage',
        role: [],
        element: React.lazy(() => import('./features/components/layouts/layout-common.component')),
        name: 'Portal',
        title: 'Portal',
        children: [
            {
                path: '/dashboard',
                role: ['user', 'admin'],
                element: React.lazy(() => import('./features/pages/dashboard/dashboard.component')),
                name: 'Dashboard',
                title: 'Dashboard',
            },{
                path: '/group-detail/:id',
                role: ['user', 'admin'],
                element: React.lazy(() => import('./features/pages/group-detail/group-detail.component')),
                name: 'Detail',
                title: 'Detail',
            },{
                path: '/accounts',
                role: ['admin'],
                element: React.lazy(() => import('./features/pages/users/account-page.component')),
                name: 'Manage Accounts',
                title: 'Manage Accounts',
            },{
                path: '/configuration',
                role: ['admin'],
                element: React.lazy(() => import('./features/pages/configuration/configuration-page.component')),
                name: 'Manage Accounts',
                title: 'Manage Accounts',
            },{
                path: '/my-information',
                role: ['user', 'admin'],
                element: React.lazy(() => import('./features/pages/my-information/my-information-page.component')),
                name: 'My Information',
                title: 'My Information',
            },
        ],
    }, {
        path: '/auth',
        role: [],
        element: React.lazy(() => import('./features/components/layouts/layout-common.component')),
        name: 'Portal',
        title: 'Portal',
        children: [
            {
                path: '/landing',
                role: [],
                element: React.lazy(() => import('./features/pages/landing/landing.component')),
                name: 'Landing',
                title: 'Landing',
            }, {
                path: '/failure',
                role: [],
                element: React.lazy(() => import('./features/pages/dashboard/dashboard.component')),
                name: 'Dashboard',
                title: 'Dashboard',
            }, {
                path: '/success',
                role: ['user', 'admin'],
                element: React.lazy(() => import('./features/pages/dashboard/dashboard.component')),
                name: 'Dashboard',
                title: 'Dashboard',
            }
        ],
    },
];

const createRoute: any = ({ path, element, title, name, role, children, ...route }
    : {path: string; element?: JSX.Element | React.ReactNode | React.FunctionComponent;
        title: string; name: string; role: string[]; children?: CustomizeRoute[]
    }) : React.ReactElement => {
        
    const Component: any = (role.length > 0 ? withAuth(element) : element);

    return (
        <Route key={path} path={path} {...route} element={<Component {...route} />}>
            {
                children && children.map((item) => createRoute({
                    path: `${path}${item.path}`,
                    element: item.element,
                    title: item.title,
                    name: item.name,
                    role: item.role,
                    children: item.children,
                    ...route
                    })
                )
            }
        </Route>
    );
};

const RoutersLayout = (props: any) => {
    return  <Routes>
                { routes.map(createRoute) }
            </Routes>;
}

export default RoutersLayout;
