
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { combineEpics } from 'redux-observable';

import * as fromAction from '../reducers/account.slice';
import { IResponseObj } from '../../models/base.model';

import AccountService from '../../services/account.service';
import {
    IAccountActionInputDto,
    IAccountSearchInputDto,
    IAccountSearchOutputDto
} from '../../models/account.model';

export const accountEpic = combineEpics(
    // Search Account
    (action$:Observable<Action>) => {
        return action$.pipe(
            ofType (fromAction.fetchAccountSearchStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IAccountSearchInputDto) => 
                AccountService.searchAccount(inputData)
            .pipe(
                map((response: IResponseObj<IAccountSearchOutputDto[]>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.fetchAccountSearchCompleted(response.data);
                    } else {
                        // throw response;
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    let result = {
                        message: err
                    }
                    return of(fromAction.execError(''));
                })
            ))
        );
    },
    // Request Approved Account
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.accountApprovedStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IAccountActionInputDto) => 
                AccountService.requestActionAccount(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.accountApprovedCompleted({
                            accountId: inputData.accountId,
                            isApproved: inputData.flag
                        });
                    } else {
                        // throw response;
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    let result = {
                        message: err
                    }
                    return of(fromAction.execError(''));
                })
            ))
        );
    },
    // Request Locked Account
    (action$: any) => {
        return action$.pipe(
            ofType (fromAction.accountLockedStarted),
            map((action: any) => action.payload),
            switchMap((inputData: IAccountActionInputDto) => 
                AccountService.requestActionAccount(inputData)
            .pipe(
                map((response: IResponseObj<boolean>) => {
                    if (response && response.isSuccessed) {
                        return fromAction.accountLockedCompleted({
                            accountId: inputData.accountId,
                            isLocked: inputData.flag
                        });
                    } else {
                        // throw response;
                        return fromAction.execError(response.errorCode);
                    }
                }),
                catchError((err) => {
                    let result = {
                        message: err
                    }
                    return of(fromAction.execError(''));
                })
            ))
        );
    },
)
