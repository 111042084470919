import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITokenResponseDto, ITokenRequestDto } from '../../models/auth.model';
import { ICommonState } from '../states/common.state';

export interface IAuthState extends ICommonState{
    auth: ITokenResponseDto | null;
}

export const initialState: IAuthState = {
    error: '',
    loaded: false,
    loading: false,
    auth: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetError: (state: IAuthState) => {
            state.loading = false;
            state.loaded = true;
            state.error = '';
        },
        execError: (state: IAuthState, action: PayloadAction<string>) => {
            state.loading = false;
            state.loaded = true;
            state.error = action.payload;
        },
        // Request Token
        fetchTokenRequestStarted: (state: IAuthState, action: PayloadAction<ITokenRequestDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        fetchTokenRequestCompleted: (state: IAuthState, action: PayloadAction<ITokenResponseDto>) => {
            state.loading = false;
            state.loaded = true;
        },
    },
});

export const {
    resetError,
    execError,
    fetchTokenRequestStarted,
    fetchTokenRequestCompleted,
} = authSlice.actions

export default authSlice.reducer