import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonState } from '../states/common.state';
import {
    IAccountActionInputDto,
    IAccountApprovedOutputDto,
    IAccountLockedOutputDto,
    IAccountSearchInputDto,
    IAccountSearchOutputDto
} from '../../models/account.model';

export interface IAccountState extends ICommonState{
    accounts: IAccountSearchOutputDto[] | [];
}

export const initialState: IAccountState = {
    error: '',
    loaded: false,
    loading: false,
    accounts: [],
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        resetError: (state: IAccountState) => {
            state.loading = false;
            state.loaded = true;
            state.error = '';
        },
        execError: (state: IAccountState, action: PayloadAction<string>) => {
            state.loading = false;
            state.loaded = true;
            state.error = action.payload;
        },
        // Search Account
        fetchAccountSearchStarted: (state: IAccountState, action: PayloadAction<IAccountSearchInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        fetchAccountSearchCompleted: (state: IAccountState, action: PayloadAction<IAccountSearchOutputDto[]>) => {
            state.loading = false;
            state.loaded = true;
            state.accounts = action.payload;
        },
        // Approved Account
        accountApprovedStarted: (state: IAccountState, action: PayloadAction<IAccountActionInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        accountApprovedCompleted: (state: IAccountState, action: PayloadAction<IAccountApprovedOutputDto>) => {
            const accounts = [...state.accounts??[]]
            let newAccounts: IAccountSearchOutputDto[] = [];
            if (action.payload != null) {
                for (const item of accounts) {
                    if (item.id === action.payload.accountId) {
                        newAccounts.push({
                            id: item.id,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            email: item.email,
                            photo: item.photo,
                            validate: item.validate,
                            isApproved: action.payload.isApproved,
                            isLocked: item.isLocked,
                            createdAt: item.createdAt,
                            refreshLicenseAt: item.refreshLicenseAt,
                            availableTo: item.availableTo
                        });

                        continue;
                    }

                    newAccounts.push({
                        id: item.id,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        email: item.email,
                        photo: item.photo,
                        validate: item.validate,
                        isApproved: item.isApproved,
                        isLocked: item.isLocked,
                        createdAt: item.createdAt,
                        refreshLicenseAt: item.refreshLicenseAt,
                        availableTo: item.availableTo
                    });
                }
            }

            state.loading = false;
            state.loaded = true;
            state.accounts = newAccounts
        },
        // Locled Account
        accountLockedStarted: (state: IAccountState, action: PayloadAction<IAccountActionInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        accountLockedCompleted: (state: IAccountState, action: PayloadAction<IAccountLockedOutputDto>) => {
            const accounts = [...state.accounts??[]]
            let newAccounts: IAccountSearchOutputDto[] = [];
            if (action.payload != null) {
                for (const item of accounts) {
                    if (item.id === action.payload.accountId) {
                        newAccounts.push({
                            id: item.id,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            email: item.email,
                            photo: item.photo,
                            validate: item.validate,
                            isApproved: item.isApproved,
                            isLocked: action.payload.isLocked,
                            createdAt: item.createdAt,
                            refreshLicenseAt: item.refreshLicenseAt,
                            availableTo: item.availableTo
                        });

                        continue;
                    }

                    newAccounts.push({
                        id: item.id,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        email: item.email,
                        photo: item.photo,
                        validate: item.validate,
                        isApproved: item.isApproved,
                        isLocked: item.isLocked,
                        createdAt: item.createdAt,
                        refreshLicenseAt: item.refreshLicenseAt,
                        availableTo: item.availableTo
                    });
                }
            }

            state.loading = false;
            state.loaded = true;
            state.accounts = newAccounts
        },
    },
});

export const {
    resetError,
    execError,
    fetchAccountSearchStarted,
    fetchAccountSearchCompleted,
    accountApprovedStarted,
    accountApprovedCompleted,
    accountLockedStarted,
    accountLockedCompleted
} = accountSlice.actions

export default accountSlice.reducer