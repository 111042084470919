import { Observable } from 'rxjs/internal/Observable';

import { post } from './../services/api-base.service';
import { IResponseObj } from '../models/base.model';
import {
    IAccountActionInputDto,
    IAccountSearchInputDto,
    IAccountSearchOutputDto
} from '../models/account.model';

export default class AccountService {
    static searchAccount(data: IAccountSearchInputDto) : Observable<IResponseObj<IAccountSearchOutputDto[]>> {
        return post('account/search', data);
    }

    static requestActionAccount(data: IAccountActionInputDto) : Observable<IResponseObj<boolean>> {
        return post('account/request-action', data);
    }
}