import axios, { AxiosHeaders, AxiosResponse } from 'axios';
import { catchError, from, map, Observable, of } from 'rxjs';
import initInterceptors from '../core/interceptor';
import {BACKEND_URL} from '../environment/app-environment';

const getInstance = axios.create({
    baseURL: BACKEND_URL,
    // headers: getHeader({}),
    timeout: 60000,
});

initInterceptors(getInstance);

export function getHeader(dataType: string = ''): AxiosHeaders  {
  let header = new AxiosHeaders();
  switch(dataType) {
    case 'FILE':
      header.setContentType('multipart/form-data', true);
      break;

    default:
      header.setContentType('application/json', true);
      break;
  }
  return header;
}

export function customFrom(requestConfig: any) {
  const obs = new Observable(subscriber => {
    const source = axios.CancelToken.source();
    getInstance({ ...requestConfig, cancelToken: source.token })
      .then(response => {
        subscriber.next(response);
        subscriber.complete();
      })
      .catch(error => {
        subscriber.error(error);
      });
    return () => {
      source.cancel();
    };
  });
  return obs;
}

export function post<T, TResult>(url: string, data: T, dataType: string = ''): Observable<TResult> {
  const requestUrl = `${BACKEND_URL}/api/${url}`;
  const options = getHeader(dataType);

  return from(getInstance.post(requestUrl, data, { headers: options }))
        .pipe(
            map((response:AxiosResponse<any, TResult>) => response.data),
            catchError((err) => of(err))
        )
}

export function get<T, TResult>(url: string, data: string = '', dataType: string = ''): Observable<TResult> {
  const requestUrl = `${BACKEND_URL}/api/${url}${(data && data.length > 0 ? ('?' + data) : '')}`;
  const options = getHeader(dataType);

  return from(getInstance.get(requestUrl, { headers: options }))
        .pipe(
            map((response:AxiosResponse<any, TResult>) => response.data),
            catchError((err) => of(err))
        )
}

export default getInstance;