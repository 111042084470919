import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITokenResponseDto, ITokenRequestDto } from '../../models/auth.model';
import { ICommonState } from '../states/common.state';

export interface IDatabaseState extends ICommonState{
}

export const initialState: IDatabaseState = {
    error: '',
    loaded: false,
    loading: false,
}

export const databaseSlice = createSlice({
    name: 'database',
    initialState,
    reducers: {
        resetError: (state: IDatabaseState) => {
            state.loading = false;
            state.loaded = true;
            state.error = '';
        },
        execError: (state: IDatabaseState, action: PayloadAction<string>) => {
            state.loading = false;
            state.loaded = true;
            state.error = action.payload;
        },
        // Request Token
        backupRequestStarted: (state: IDatabaseState) => {
            state.loading = true;
            state.loaded = false;
        },
        backupRequestCompleted: (state: IDatabaseState, action: PayloadAction<any>) => {

            // const url = window.URL.createObjectURL(new Blob([blob]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', `sample.${this.state.file}`);
            // // 3. Append to html page
            // document.body.appendChild(link);
            // // 4. Force download
            // link.click();
            // // 5. Clean up and remove the link
            // link.parentNode.removeChild(link);

            state.loading = false;
            state.loaded = true;
        },
    },
});

export const {
    resetError,
    execError,
    backupRequestStarted,
    backupRequestCompleted,
} = databaseSlice.actions

export default databaseSlice.reducer