import { IFunctionUserOutputDto } from "../models/base.model";

function getAccessToken(): string {
    return localStorage.getItem('access-token') ?? '';
}

function setToken(token: string): void {
    setLocalStorage('access-token', token);
}

function getRefreshToken(): string {
    return localStorage.getItem('refresh-token') ?? '';
}

function setRefreshToken(refreshToken: string): void {
    setLocalStorage('refresh-token', refreshToken);
}

function getAvatar(): string {
    return localStorage.getItem('avatar') ?? '';
}

function getFullName(): string {
    const firstName = localStorage.getItem('firstName') ?? '';
    const lastName = localStorage.getItem('lastName') ?? '';

    return firstName + '.' + lastName;
}

function setLocalStorage(key: string, value: string): void {
    localStorage.setItem(key, value);
}

function clearData(): void {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
}

function getFunctions(): IFunctionUserOutputDto[] {
    let result: IFunctionUserOutputDto[] = [];
    const funcStr = localStorage.getItem('functions') ?? '';
    if (funcStr == null || funcStr == undefined || funcStr == 'undefined' || funcStr.length == 0) {
        return result;
    }

    result = JSON.parse(funcStr);
    return result;
}

export default {
    setLocalStorage,
    setToken,
    getAccessToken,
    getRefreshToken,
    setRefreshToken,
    getAvatar,
    clearData,
    getFullName,
    getFunctions
};