import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonState } from '../states/common.state';
import {
    IMyLicenseInfoOutputDto,
    IPlanInfoChangePositionInputDto,
    IPlanInfoChangePositionOutputDto,
    IPlanInfoInputDto,
    IPlanInfoOutputDto,
    IPlanInfoUpdateStatusInputDto,
    IPlanInfoUpdateStatusOutputDto
} from '../../models/plan.model';
import { IRequestId } from '../../models/base.model';

export interface IPlanState extends ICommonState{
    plans: IPlanInfoOutputDto[],
    plan: IPlanInfoOutputDto | null,
    myLicenses: IMyLicenseInfoOutputDto[],
}

export const initialState: IPlanState = {
    error: '',
    loaded: false,
    loading: false,
    plans: [],
    plan: null,
    myLicenses: []
}

export const planSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        resetError: (state: IPlanState) => {
            state.loading = false;
            state.loaded = true;
            state.error = '';
        },
        execError: (state: IPlanState, action: PayloadAction<string>) => {
            state.loading = false;
            state.loaded = true;
            state.error = action.payload;
        },
        // Get Plans
        fetchAllPlansStarted: (state: IPlanState) => {
            state.loading = true;
            state.loaded = false;
        },
        fetchAllPlansCompleted: (state: IPlanState, action: PayloadAction<IPlanInfoOutputDto[]>) => {
            state.loading = false;
            state.loaded = true;
            state.plans = action.payload;
        },
        // Avaiable Plans
        fetchAvaiablePlansStarted: (state: IPlanState) => {
            state.loading = true;
            state.loaded = false;
        },
        fetchAvaiablePlansCompleted: (state: IPlanState, action: PayloadAction<IPlanInfoOutputDto[]>) => {
            state.loading = false;
            state.loaded = true;
            state.plans = action.payload;
        },
        // get Plan by Id
        fetchPlanByIdStarted: (state: IPlanState, action: PayloadAction<IRequestId<string>>) => {
            state.loading = true;
            state.loaded = false;
        },
        fetchPlanByIdCompleted: (state: IPlanState, action: PayloadAction<IPlanInfoOutputDto>) => {
            state.loading = false;
            state.loaded = true;
            state.plan = action.payload;
        },
        // Add new Plan
        addNewPlanStarted: (state: IPlanState, action: PayloadAction<IPlanInfoInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        addNewPlanCompleted: (state: IPlanState, action: PayloadAction<IPlanInfoOutputDto>) => {
            const plans = [...state.plans??[]]
            let newPlans: IPlanInfoOutputDto[] = [];
            if (action.payload !== null && action.payload.id.length > 0) {
                for (const item of plans) {
                    newPlans.push({
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        duration: item.duration,
                        durationContent: item.durationContent,
                        content1: item.content1,
                        content2: item.content2,
                        content3: item.content3,
                        isActived: item.isActived,
                        position: item.position
                    });
                }

                newPlans.push({
                    id: action.payload.id,
                    name: action.payload.name,
                    price: action.payload.price,
                    duration: action.payload.duration,
                    durationContent: action.payload.durationContent,
                    content1: action.payload.content1,
                    content2: action.payload.content2,
                    content3: action.payload.content3,
                    isActived: action.payload.isActived,
                    position: action.payload.position
                });
            }

            state.loading = false;
            state.loaded = true;
            state.plans = newPlans;
        },
        // Update Plan
        updatePlanStarted: (state: IPlanState, action: PayloadAction<IPlanInfoInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        updatePlanCompleted: (state: IPlanState, action: PayloadAction<IPlanInfoOutputDto>) => {
            const plans = [...state.plans??[]]
            let newPlans: IPlanInfoOutputDto[] = [];
            if (action.payload !== null && action.payload.id.length > 0) {
                for (const item of plans) {
                    if(item.id !== action.payload.id) {
                        newPlans.push({
                            id: item.id,
                            name: item.name,
                            price: item.price,
                            duration: item.duration,
                            durationContent: item.durationContent,
                            content1: item.content1,
                            content2: item.content2,
                            content3: item.content3,
                            isActived: item.isActived,
                            position: item.position
                        });
                        continue;
                    }

                    newPlans.push({
                        id: action.payload.id,
                        name: action.payload.name,
                        price: action.payload.price,
                        duration: action.payload.duration,
                        durationContent: action.payload.durationContent,
                        content1: action.payload.content1,
                        content2: action.payload.content2,
                        content3: action.payload.content3,
                        isActived: action.payload.isActived,
                        position: action.payload.position
                    });
                }
            }

            state.loading = false;
            state.loaded = true;
            state.plans = newPlans;
        },
        // Update Status of Plan
        updateStatusPlanStarted: (state: IPlanState, action: PayloadAction<IPlanInfoUpdateStatusInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        updateStatusPlanCompleted: (state: IPlanState, action: PayloadAction<IPlanInfoUpdateStatusOutputDto>) => {
            const plans = [...state.plans??[]]
            let newPlans: IPlanInfoOutputDto[] = [];
            if (action.payload !== null && action.payload.id.length > 0) {
                for (const item of plans) {
                    if(item.id !== action.payload.id) {
                        newPlans.push({
                            id: item.id,
                            name: item.name,
                            price: item.price,
                            duration: item.duration,
                            durationContent: item.durationContent,
                            content1: item.content1,
                            content2: item.content2,
                            content3: item.content3,
                            isActived: item.isActived,
                            position: item.position
                        });
                        continue;
                    }

                    newPlans.push({
                        id: action.payload.id,
                        name: item.name,
                        price: item.price,
                        duration: item.duration,
                        durationContent: item.durationContent,
                        content1: item.content1,
                        content2: item.content2,
                        content3: item.content3,
                        isActived: action.payload.isActived,
                        position: item.position
                    });
                }
            }

            state.loading = false;
            state.loaded = true;
            state.plans = newPlans;
        },
        // Delete Plan
        deletePlanStarted: (state: IPlanState, action: PayloadAction<IRequestId<string>>) => {
            state.loading = true;
            state.loaded = false;
        },
        deletePlanCompleted: (state: IPlanState, action: PayloadAction<string>) => {
            const plans = [...state.plans??[]]
            let newPlans: IPlanInfoOutputDto[] = [];
            if (action.payload !== null && action.payload.length > 0) {
                for (const item of plans) {
                    if(item.id === action.payload) {
                        continue;
                    }

                    newPlans.push({
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        duration: item.duration,
                        durationContent: item.durationContent,
                        content1: item.content1,
                        content2: item.content2,
                        content3: item.content3,
                        isActived: item.isActived,
                        position: item.position
                    });
                }
            }

            state.loading = false;
            state.loaded = true;
            state.plans = newPlans;
        },
        // Change Position
        changePositionPlanStarted: (state: IPlanState, action: PayloadAction<IPlanInfoChangePositionInputDto>) => {
            state.loading = true;
            state.loaded = false;
        },
        changePositionPlanCompleted: (state: IPlanState, action: PayloadAction<IPlanInfoChangePositionOutputDto>) => {
            const plans = [...state.plans??[]]
            let newPlans: IPlanInfoOutputDto[] = [];
            if (action.payload !== null) {
                let isMovement = false;

                for (let index = 0; index < plans.length; index++) {
                    if (action.payload.isUpPosition === true) {
                        if (plans[index].position === action.payload.newPosition) {
                            isMovement = true;
                        }
                    } else {
                        if (plans[index].id === action.payload.id) {
                            isMovement = true;
                        }
                    }

                    if (isMovement === true && index + 1 < plans.length) {
                        isMovement = false;
                        newPlans.push({
                            id: plans[index + 1].id,
                            name: plans[index + 1].name,
                            price: plans[index + 1].price,
                            duration: plans[index + 1].duration,
                            durationContent: plans[index + 1].durationContent,
                            content1: plans[index + 1].content1,
                            content2: plans[index + 1].content2,
                            content3: plans[index + 1].content3,
                            isActived: plans[index + 1].isActived,
                            position: plans[index].position
                        });

                        newPlans.push({
                            id: plans[index].id,
                            name: plans[index].name,
                            price: plans[index].price,
                            duration: plans[index].duration,
                            durationContent: plans[index].durationContent,
                            content1: plans[index].content1,
                            content2: plans[index].content2,
                            content3: plans[index].content3,
                            isActived: plans[index].isActived,
                            position: plans[index + 1].position
                        });

                        index++;
                    } else {
                        newPlans.push({
                            id: plans[index].id,
                            name: plans[index].name,
                            price: plans[index].price,
                            duration: plans[index].duration,
                            durationContent: plans[index].durationContent,
                            content1: plans[index].content1,
                            content2: plans[index].content2,
                            content3: plans[index].content3,
                            isActived: plans[index].isActived,
                            position: plans[index + 1].position
                        });
                    }
                }
            }

            state.loading = false;
            state.loaded = true;
            state.plans = newPlans;
        },
        // Register License
        registerLicenseStarted: (state: IPlanState, action: PayloadAction<IRequestId<string>>) => {
            state.loading = true;
            state.loaded = false;
        },
        registerLicenseCompleted: (state: IPlanState, action: PayloadAction<boolean>) => {
            state.loading = false;
            state.loaded = true;
        },
        // My License
        myLicesnsesStarted: (state: IPlanState) => {
            state.loading = true;
            state.loaded = false;
        },
        myLicensesCompleted: (state: IPlanState, action: PayloadAction<IMyLicenseInfoOutputDto[]>) => {
            state.myLicenses = action.payload;
            state.loading = false;
            state.loaded = true;
        },
    },
});

export const {
    resetError,
    execError,
    fetchAllPlansStarted,
    fetchAllPlansCompleted,
    fetchAvaiablePlansStarted,
    fetchAvaiablePlansCompleted,
    fetchPlanByIdStarted,
    fetchPlanByIdCompleted,
    addNewPlanStarted,
    addNewPlanCompleted,
    updatePlanStarted,
    updatePlanCompleted,
    updateStatusPlanStarted,
    updateStatusPlanCompleted,
    deletePlanStarted,
    deletePlanCompleted,
    changePositionPlanStarted,
    changePositionPlanCompleted,
    registerLicenseStarted,
    registerLicenseCompleted,
    myLicesnsesStarted,
    myLicensesCompleted
} = planSlice.actions

export default planSlice.reducer